<template>
  <div :class="contentDiv">
    <app-progress-nav
      :helpModalId="progressNav.helpModalId"
      :progress="progressNav.progress"
      :showButtonBack="progressNav.showButtonBack"
      :stepsText="progressNav.stepsText"
      :title="progressNav.title"
      :visiblePc="progressNav.visiblePc"
      v-show="progressNav.show === true"
    ></app-progress-nav>
    <step-1-content v-show="progressNav.step === 1" />
    <step-1-footer v-show="progressNav.step === 1" />

    <step-2-type-1-content
      :predeterminedPointList='predeterminedPointList'
      v-show="progressNav.step === 2 && type === '1'" ref='test123456'
      @update-predeterminedPointList="updatePredeterminedPointList"
      :step='progressNav.step'
    />
    <step-2-type-1-footer v-show="progressNav.step === 2 && type === '1'" />

    <step-2-type-2-content v-show="progressNav.step === 2 && type === '2'" />
    <step-2-type-2-footer v-show="progressNav.step === 2 && type === '2'" />

    <step-3-type-1-content v-show="progressNav.step === 3 && type === '1'" />
    <step-3-type-1-footer
      v-show="progressNav.step === 3 && type === '1'"
      @getDefaultContact="getDefaultContact"
    />

    <step-3-type-2-content v-show="progressNav.step === 3 && type === '2'" />
    <step-3-type-2-footer
      v-show="progressNav.step === 3 && type === '2'"
      @getDefaultContact="getDefaultContact"
    />

    <step-4-content v-show="progressNav.step === 4" />
    <step-4-footer v-show="progressNav.step === 4" />
    <order-confirmation-content v-show="progressNav.step === 5 && ((!order.card || order.card.cardId !== -1) || order.fare == 0)" />
    <order-confirmation-footer v-show="progressNav.step === 5 && ((!order.card || order.card.cardId !== -1) || order.fare == 0)" />
    <order-pending-payment-content v-show="progressNav.step === 5 && order.card && order.card.cardId === -1 && order.fare > 0" />
    <order-pending-payment-footer v-show="progressNav.step === 5 && order.card && order.card.cardId === -1 && order.fare > 0" />
    <div>
      <modal-scharff-point-slider />
      <!-- <modal-quoter /> -->
      <modal-restriction />
      <!-- <modal-address id="modal-add-address" :disableCloseButton="'false'" /> -->

      <ModalFavoriteContactsV2
        id="modal-contact-origin"
        @getFavoriteContact="getContact"
      />
      <ModalFavoriteContactsV2
        id="modal-contact-destination"
        @getFavoriteContact="getContact"
      />

      <ModalFavoriteAddressV2
        id="modal-favorite-address"
        @getFavoriteAddress="setAddress"
      />

      <!-- <modal-scharff-point-package id="modal-package" /> -->
      <ModalScharffPointPackageV2 id="modal-package" />

      <!-- <modal-scharff-point-type-2-package id="modal-package-type-2" /> -->
      <ModalScharffPointType2PackageV2 id="modal-package-type-2" />

      <modal-coupon id="modalCoupon" @getSelectedCoupon="getCoupon" />
      <modal-type-voucher
        id="modalTypeVoucher"
        @getVoucherData="getVoucherData"
      />
      <modal-type-card id="modalTypeCard" @getCardData="getCardData" />
      <!--<modal-scharff-real-plaza id="modalScharffRealPlaza" />-->
    </div>
  </div>
</template>

<script>
import AppProgressNav from "../components/General/AppProgressNav.vue";
import Step1Content from "../components/PuntoScharff/Step1/Step1-Content.vue";
import Step1Footer from "../components/PuntoScharff/Step1/Step1-Footer.vue";

import Step2Type1Content from "../components/PuntoScharff/Step2/Step2-Type1-Content.vue";
import Step2Type1Footer from "../components/PuntoScharff/Step2/Step2-Type1-Footer.vue";

import Step2Type2Content from "../components/PuntoScharff/Step2/Step2-Type2-Content.vue";
import Step2Type2Footer from "../components/PuntoScharff/Step2/Step2-Type2-Footer.vue";

import Step3Type1Content from "../components/PuntoScharff/Step3/Step3-Type1-Content.vue";
import Step3Type1Footer from "../components/PuntoScharff/Step3/Step3-Type1-Footer.vue";

import Step3Type2Content from "../components/PuntoScharff/Step3/Step3-Type2-Content.vue";
import Step3Type2Footer from "../components/PuntoScharff/Step3/Step3-Type2-Footer.vue";

import Step4Content from "../components/PuntoScharff/Step4/Step4-Content.vue";
import Step4Footer from "../components/PuntoScharff/Step4/Step4-Footer.vue";
import OrderConfirmationContent from "../components/PuntoScharff/OrderConfirmation/OrderConfirmation-Content.vue";
import OrderConfirmationFooter from "../components/PuntoScharff/OrderConfirmation/OrderConfirmation-Footer.vue";

import OrderPendingPaymentContent from "../components/Shared/OrderPendingPayment/OrderPendingPayment-Content.vue";
import OrderPendingPaymentFooter from "../components/Shared/OrderPendingPayment/OrderPendingPayment-Footer.vue";

import ModalScharffPointSlider from "../components/Modals/ModalScharffPointSlider.vue";
// import ModalQuoter from "../components/Modals/ModalQuoter.vue";
import ModalRestriction from "../components/Modals/ModalScharffPointRestrictions.vue";
// import ModalAddress from "../components/Modals/ModalAddress.vue";
// import ModalFavoriteContacts from "../components/Modals/ModalFavoriteContacts.vue";
import ModalFavoriteContactsV2 from "../components/Modals/ModalFavoriteContactsV2.vue";
import ModalFavoriteAddressV2 from "../components/Modals/ModalFavoriteAddressV2.vue";
import ModalScharffPointType2Package from "../components/Modals/ModalScharffPointType2Package.vue";
import ModalTypeCard from "../components/Modals/ModalTypeCard.vue";
import ModalTypeVoucher from "../components/Modals/ModalTypeVoucher.vue";
import ModalCoupon from "../components/Modals/ModalCoupon.vue";
import ModalScharffRealPlaza from "../components/Modals/ModalScharffRealPlaza.vue";
import utils from "../commons/mixins/utils";

import ModalScharffPointPackage from "../components/Modals/ModalScharffPointPackage.vue";
import ModalScharffPointPackageV2 from "../components/Modals/ModalScharffPointPackageV2.vue";
import ModalScharffPointType2PackageV2 from "../components/Modals/ModalScharffPointType2PackageV2.vue";
import Map from "../commons/mixins/maps";

export default {
  name: "PuntoScharff",
  title: "Puntos | Tipo de envío",
  components: {
    AppProgressNav,
    Step1Content,
    Step1Footer,
    Step2Type1Content,
    Step2Type1Footer,
    Step2Type2Content,
    Step2Type2Footer,
    Step3Type1Content,
    Step3Type1Footer,
    Step3Type2Content,
    Step3Type2Footer,
    ModalScharffPointPackage,
    ModalScharffPointPackageV2,
    Step4Content,
    Step4Footer,
    ModalScharffPointSlider,
    // ModalQuoter,
    ModalRestriction,
    // ModalAddress,
    ModalFavoriteContactsV2,
    ModalFavoriteAddressV2,
    ModalScharffPointType2Package,
    ModalScharffPointType2PackageV2,
    ModalTypeCard,
    ModalCoupon,
    ModalTypeVoucher,
    ModalScharffRealPlaza,
    OrderPendingPaymentContent,
    OrderPendingPaymentFooter,
    OrderConfirmationContent,
    OrderConfirmationFooter,
  },
  data() {
    return {
      predeterminedPointList : []
    };
  },
  beforeMount() {
    this.authenticated = this.$store.getters.AUTHENTICATED;
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch("CURRENT_SERVICE", 4);
      this.$store.dispatch("SCHARFFPOINT_PROGRESS_NAV_STEP", 1);
      this.$store.dispatch("SCHARFFPOINT_ORDER_ORIGIN_CONTACT", null);
      this.initMain();
      this.initSaioMap();
      const map = new Map();
      map.initMap("mapSaio");
      this.$store.dispatch("SCHARFFPOINT_MAP_SET", map);
      this.getDefaultContact();
    });
  },
  methods: {
    getDefaultContact() {
      this.$store.dispatch("GET_DEFAULT_CONTACT").then(response => {
        if (response?.esPredeterminado) {
          const contact = {
            contactId: response.contactId,
            name: response.nombreContacto,
            phone: response.telefonoContacto,
            email: response.correoContacto,
            documentNumber: response.nroDocumentoContacto,
          };
          this.$store.dispatch("SCHARFFPOINT_ORDER_ORIGIN_CONTACT", contact);
          // this.$store.dispatch("HELPER_SET_ADD_DESTINATION_CONTACT", contact);
        }
      });
    },
    async getDefaultAddress() {
      const { toCapitalCase } = utils.methods
      this.showLoading()
      try {
        const addressList = await this.$store.dispatch('GET_FAVORITE_LOCATIONS')
        const predeterminedAddress = addressList.find(address => !address.puntoScharffId && address.isPredetermined)
        const predeterminedPoint = addressList.find(address => address.puntoScharffId && address.isPredetermined)

        this.predeterminedPointList = addressList.filter(address => address.puntoScharffId)

        if (predeterminedAddress && this.type === '2') {
          this.setAddress(predeterminedAddress)
        }

        if (predeterminedPoint && this.type === '1') {
          this.$refs.test123456.setPointFromOutside(predeterminedPoint)
        } else if (this.predeterminedPointList && this.type === '1') {
          this.$refs.test123456.setPointToShow()
        }
      } catch (e) {
        console.log({ e })
        const msg = e?.data?.message || 'Ocurrió un error.'
        this.showError(msg)
      } finally {
        this.$swal.close()
      }
    },
    updatePredeterminedPointList(newList) {
      this.predeterminedPointList = newList.filter(el => el.puntoScharffId)
    },
    setModel(origin, destination) {
      const fareRequest = {
        PackageValue: destination.productValue,
        PackageWidth: destination.packageSize.width,
        PackageHeight: destination.packageSize.height,
        PackageDeep: destination.packageSize.lengths,
        PackageWeight: destination.packageSize.weight,
        PackageType:
          destination.packageSize.type === "Pequeno"
            ? "Pequeño"
            : destination.packageSize.type,
        isMoreThan10: false,
        IsServiceExpress: false,
        IsServiceProgrammed: false,
        isServiceScharffPoint: true,
        OriginDistrictId: origin.districtId.id,
        DestinationDistrictId: destination.destinationAddress.districtId.id,
        ProgrammedServiceDate: null,
        IsUsingSubscription: false,
        Coupon:
          destination.coupon === null ? null : destination.coupon.fareCoupon,
      };
      return fareRequest;
    },
    async sleep(ms) {
      // eslint-disable-next-line no-promise-executor-return
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async addCouponDestination(lstCoupon) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.lstDestination.length; index++) {
        this.$store.dispatch("SCHARFFPOINT_ORDER_SET_INDEX_DESTINATION", index);

        const element = this.lstDestination[index];
        const coupon = lstCoupon?.listCoupon[index];
        const destinationTemp = {
          index,
          item: {
            description: element.description,
            destinationAddress: {
              description: element.destinationAddress.description,
              additionalAddress: element.destinationAddress.additionalAddress,
              lat: element.destinationAddress.lat,
              lng: element.destinationAddress.lng,
              districtId: element.destinationAddress.districtId,
              contact: element.destinationAddress.contact,
              puntoScharffId: element.destinationAddress.puntoScharffId,
            },
            productValue: element.productValue,
            packageSize: element.packageSize,
            fare: 0,
            coupon: coupon.isValid === true ? coupon : null,
          },
        };
        const obj = this.setModel(this.origin, destinationTemp.item);
        this.$store
          .dispatch("SCHARFFPOINT_CALCULATE_FARE", obj)
          .then((response) => {
            destinationTemp.item.fare = response.totalFare;
            const destination = JSON.parse(JSON.stringify(destinationTemp));
            this.$store.dispatch(
              "SCHARFFPOINT_ORDER_SET_DESTINATION",
              destination
            );
          })
          .catch((error) => {
            this.$swal({
              title: "Error!",
              text: error,
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          });

        // eslint-disable-next-line no-await-in-loop
        await this.sleep(1000);
      }
    },
    getCardData(cardData) {
      this.$store.dispatch("SCHARFFPOINT_CARD", cardData);
    },
    getVoucherData(voucherData) {
      this.$store.dispatch("SCHARFFPOINT_VOUCHER", voucherData);
    },
    async getCoupon(coupon) {
      try {
        if (coupon != null) {
          if (coupon?.listCoupon[0].isValid) {
            this.$store.dispatch("SCHARFFPOINT_COUPON", coupon?.listCoupon[0]);
            const destinations = this.order.Destination;
            coupon?.listCoupon.forEach((element, index) => {
              if (element.isValid === true) {
                destinations[index].coupon = element.code;
                if (element.fareCoupon.Type === "VAL") {
                  destinations[index].discountFare = (
                    destinations[index].fare - element.fareCoupon.Value
                  ).toFixed(2);
                } else {
                  destinations[index].discountFare = (
                    destinations[index].fare -
                    destinations[index].fare * element.fareCoupon.Value
                  ).toFixed(2);
                }
                if (destinations[index].discountFare <= 0)
                  destinations[index].discountFare = 0;
              }
            });
            this.$store.dispatch(
              "SCHARFFPOINT_APPEND_DESTINATION",
              destinations
            );
            this.showLoading();
            console.table(this.order.Destination);
            const sumall = this.order.Destination.map((item) =>
            (item.coupon ? parseFloat(item.discountFare) : Number(item.fare) )
            ).reduce((prev, curr) => prev + curr, 0);
            this.$store.dispatch("SCHARFFPOINT_FARE", sumall);
            this.$swal.close();
          } else {
            this.showError("Ya no puedes usar este cupón");
          }
        } else {
          this.showError("Cupón invalido");
        }
      } catch (error) {
        console.error(error);
      }
    },
    setAddress(address) {
      this.$store.dispatch('SCHARFFPOINT_ORDER_ORIGIN_ADDRESS', {
        favoriteLocationId: address.favoriteLocationId,
        name: address.name,
        description: address.address,
        additionalAddress: address.addressAditional,
        lat: address.location?.Lat || 0,
        lng: address.location?.Lng || 0,
        departmentName: address.departmentName,
        provinceName: address.provinceName,
        districtName: address.districtName,
      })
      this.$store.dispatch('SCHARFFPOINT_ORDER_ORIGIN_DISTRICT', { id: address.distrito })
    },
    getContact(Contact) {
      if (this.modalContactType === "origin") {
        const oriContact = {
          contactId: Contact.contactId,
          name: Contact.nombreContacto,
          phone: Contact.telefonoContacto,
          email: Contact.correoContacto,
          documentNumber: Contact.nroDocumentoContacto,
        };
        this.$store.dispatch("SCHARFFPOINT_ORDER_ORIGIN_CONTACT", oriContact);
      } else if (this.modalContactType === "destination") {
        const destContact = {
          contactId: Contact.contactId,
          name: Contact.nombreContacto,
          phone: Contact.telefonoContacto,
          email: Contact.correoContacto,
          documentNumber: Contact.nroDocumentoContacto,
        };
        this.$store.dispatch("HELPER_SET_ADD_DESTINATION_CONTACT", destContact);
      } else {
        this.showError("Modal tipo de contacto no reconocido.");
      }
    },
  },
  computed: {
    type() {
      return this.$store.getters.SCHARFFPOINT_ORDER_TYPE;
    },
    progressNav() {
      return this.$store.getters.SCHARFFPOINT_PROGRESS_NAV;
    },
    contentDiv() {
      return this.$store.getters.STYLE_CONTENT_DIV;
    },
    modalContactType() {
      return this.$store.getters.MODAL_CONTACT_TYPE;
    },
    stylesScharffPoint() {
      return this.$store.getters.SCHARFFPOINT_STYLES;
    },
    lstDestination() {
      return this.$store.getters.SCHARFFPOINT_ORDER_DESTINATION;
    },
    origin() {
      return this.$store.getters.PROGRAMMED_ORDER_ORIGIN;
    },
    order() {
      return this.$store.getters.PROGRAMMED_ORDER;
    },
  },
  watch: {
    stylesScharffPoint: {
      handler() {
        this.$store.dispatch("STYLE_MAP_SAIO", this.stylesScharffPoint.mapSaio);
        this.$store.dispatch(
          "STYLE_CONTENT_DIV",
          this.stylesScharffPoint.contentDiv
        );
        this.$store.dispatch(
          "STYLE_CUSTOM_DIV",
          this.stylesScharffPoint.generalDiv
        );
      },
      deep: true,
    },
    "progressNav.step": function (val) {
      if (val === 2) {
        this.getDefaultAddress()
        this.$refs.test123456.handleScroll()
        setTimeout(() => this.$refs.test123456.forceRerender(), 500)
      }
    },
    '$store.getters.SCHARFFPOINT_PROGRESS_NAV_STEP': function(val) {
      if (val === 5 && this.type === '1') {
        if (this.order.card && this.order.card.cardId === -1 && this.order.fare > 0) {
          document.title = 'Puntos | Pago efectivo'
          this.$router.push({ name: 'PuntoScharffStep5PuntosPagoEfectivo' })
        } else {
          document.title = 'Puntos | Confirmación exitosa'
          this.$router.push({ name: 'PuntoScharffStep5Puntos' })
        }
        return
      }
      if (val === 5 && this.type === '2') {
        if (this.order.card && this.order.card.cardId === -1 && this.order.fare > 0) {
          document.title = 'PickUp | Pago efectivo'
          this.$router.push({ name: 'PuntoScharffStep5PickupPagoEfectivo' })
        } else {
          document.title = 'PickUp | Confirmación exitosa'
          this.$router.push({ name: 'PuntoScharffStep5Pickup' })
        }
        return
      }
      const routesMap = {
        1: {
          '1': { title: 'Puntos | Tipo de envío', routeName: 'PuntoScharffStep1Puntos' },
          '2': { title: 'Puntos | Tipo de envío', routeName: 'PuntoScharffStep1Puntos' }
        },
        2: {
          '1': { title: 'Puntos | Datos de Envío', routeName: 'PuntoScharffStep2Puntos' },
          '2': { title: 'PickUp | Datos de Recojo', routeName: 'PuntoScharffStep2Pickup' }
        },
        3: {
          '1': { title: 'Puntos | Gestiona tus paquetes', routeName: 'PuntoScharffStep3Puntos' },
          '2': { title: 'PickUp | Gestiona tus paquetes', routeName: 'PuntoScharffStep3Pickup' }
        },
        4: {
          '1': { title: 'Puntos | Datos de Confirmación', routeName: 'PuntoScharffStep4Puntos' },
          '2': { title: 'PickUp | Datos de Confirmación', routeName: 'PuntoScharffStep4Pickup' }
        },
        5: {
          '1': { title: 'Puntos | Confirmación pago', routeName: 'PuntoScharffStep5Puntos' },
          '2': { title: 'PickUp | Confirmación pago', routeName: 'PuntoScharffStep5Pickup' }
        }
      }

      const route = routesMap[val][this.type]
      document.title = route.title
      this.$router.push({ name: route.routeName })
    },
    '$store.getters.SCHARFFPOINT_ORDER_FARE': function(val) {
      if(this.$store.getters.SCHARFFPOINT_PROGRESS_NAV_STEP === 5) {
        if (val > 0 && this.type === '1') {
          document.title = 'Puntos | Pago efectivo'
          this.$router.push({ name: 'PuntoScharffStep5PuntosPagoEfectivo' })
        }

        if (val > 0 && this.type === '2') {
          document.title = 'PickUp | Pago efectivo'
          this.$router.push({ name: 'PuntoScharffStep5PickupPagoEfectivo' })
        }

        if (val === 0 && this.type === '1') {
          document.title = 'Puntos | Confirmación exitosa'
          this.$router.push({ name: 'PuntoScharffStep5Puntos' })
        }
        if (val === 0 && this.type === '2') {
          document.title = 'PickUp | Confirmación exitosa'
          this.$router.push({ name: 'PuntoScharffStep5Pickup' })
        }
      }
    },
  },
};
</script>

<style></style>
