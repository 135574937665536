<template>
  <div :class="contentDiv">
    <app-progress-nav
      :helpModalId="progressNav.helpModalId"
      :progress="progressNav.progress"
      :showButtonBack="progressNav.showButtonBack"
      :stepsText="progressNav.stepsText"
      :title="progressNav.title"
      :visiblePc="progressNav.visiblePc"
      v-show="progressNav.show === true"
    ></app-progress-nav>
    <step-1-content v-show="progressNav.step === 1" />
    <step-1-footer v-show="progressNav.step === 1" />
    <step-2-content v-show="progressNav.step === 2" />
    <step-2-footer v-show="progressNav.step === 2" />
    <step-3-content v-show="progressNav.step === 3" />
    <step-3-footer
      v-show="progressNav.step === 3"
      @getDefaultContact="getDefaultContact"
    />
    <step-4-content v-show="progressNav.step === 4" />
    <step-4-footer v-show="progressNav.step === 4" />
    <order-confirmation-content v-show="progressNav.step === 5 && ((!order.card || order.card.cardId !== -1) || order.fare == 0)" />
    <order-confirmation-footer v-show="progressNav.step === 5 && ((!order.card || order.card.cardId !== -1) || order.fare == 0)" />
    <order-pending-payment-content v-show="progressNav.step === 5 && order.card && order.card.cardId === -1 && order.fare > 0" />
    <order-pending-payment-footer v-show="progressNav.step === 5 && order.card && order.card.cardId === -1 && order.fare > 0" />

    <div>
      <modal-programmed-slider />
      <modal-quoter />
      <modal-restriction />
      <modal-address id="modal-add-address" :disableCloseButton="'false'" />
      <!-- <modal-favorite-contacts
        id="modal-contact"
        @getFavoriteContact="getContact"
      /> -->
      <!-- <modal-programmed-package id="modal-package"></modal-programmed-package> -->
      <ModalProgrammedPackageV2 id="modal-package" />
      <modal-select-date :id="'modal-date'"></modal-select-date>
      <modal-coupon id="modalCoupon" @getSelectedCoupon="getCoupon" />
      <modal-type-voucher
        id="modalTypeVoucher"
        @getVoucherData="getVoucherData"
      />
      <modal-type-card id="modalTypeCard" @getCardData="getCardData" />


      <ModalFavoriteContactsV2
        id="modal-contact-origin"
        @getFavoriteContact="getContact"
      />
      <ModalFavoriteContactsV2
        id="modal-contact-destination"
        @getFavoriteContact="getContact"
      />
      <ModalFavoriteAddressV2
        id="modal-favorite-address"
        @getFavoriteAddress="setAddress"
      />

    </div>
  </div>
</template>

<script>
import AppProgressNav from "../components/General/AppProgressNav.vue";
import Step1Content from "../components/Programmed/Step1/Step1-Content.vue";
import Step2Content from "../components/Programmed/Step2/Step2-Content.vue";
import Step1Footer from "../components/Programmed/Step1/Step1-Footer.vue";
import Step2Footer from "../components/Programmed/Step2/Step2-Footer.vue";
import Step3Content from "../components/Programmed/Step3/Step3-Content.vue";
import Step3Footer from "../components/Programmed/Step3/Step3-Footer.vue";
import Step4Content from "../components/Programmed/Step4/Step4-Content.vue";
import Step4Footer from "../components/Programmed/Step4/Step4-Footer.vue";
import OrderConfirmationContent from "../components/Programmed/OrderConfirmation/OrderConfirmation-Content.vue";
import OrderConfirmationFooter from "../components/Programmed/OrderConfirmation/OrderConfirmation-Footer.vue";
import OrderPendingPaymentContent from "../components/Shared/OrderPendingPayment/OrderPendingPayment-Content.vue";
import OrderPendingPaymentFooter from "../components/Shared/OrderPendingPayment/OrderPendingPayment-Footer.vue";

import ModalCoupon from "../components/Modals/ModalCoupon.vue";
import ModalProgrammedSlider from "../components/Modals/ModalProgrammedSlider.vue";
import ModalQuoter from "../components/Modals/ModalQuoter.vue";
import ModalRestriction from "../components/Modals/ModalProgrammedRestricions.vue";
import ModalAddress from "../components/Modals/ModalAddress.vue";
import ModalFavoriteContacts from "../components/Modals/ModalFavoriteContacts.vue";
import ModalProgrammedPackage from "../components/Modals/ModalProgrammedPackage.vue";
import ModalSelectDate from "../components/Modals/ModalSelectDate.vue";
import ModalTypeCard from "../components/Modals/ModalTypeCard.vue";
import ModalTypeVoucher from "../components/Modals/ModalTypeVoucher.vue";
import Map from "../commons/mixins/maps";

import ModalProgrammedPackageV2 from "../components/Modals/ModalProgrammedPackageV2.vue";
import ModalFavoriteContactsV2 from "../components/Modals/ModalFavoriteContactsV2.vue";
import ModalFavoriteAddressV2 from "../components/Modals/ModalFavoriteAddressV2.vue";

export default {
  name: "ProgrammedView",
  title: "Programados | Fecha de recojo",
  components: {
    AppProgressNav,
    Step1Content,
    Step1Footer,
    Step2Content,
    Step2Footer,
    Step3Content,
    Step3Footer,
    Step4Content,
    Step4Footer,
    ModalProgrammedSlider,
    ModalQuoter,
    ModalRestriction,
    ModalAddress,
    ModalFavoriteContacts,
    ModalProgrammedPackage,
    ModalProgrammedPackageV2,
    ModalSelectDate,
    ModalTypeCard,
    ModalCoupon,
    ModalTypeVoucher,
    OrderConfirmationContent,
    OrderConfirmationFooter,
    OrderPendingPaymentContent,
    OrderPendingPaymentFooter,
    ModalFavoriteContactsV2,
    ModalFavoriteAddressV2,
  },
  data() {
    return {};
  },
  beforeMount() {
    this.authenticated = this.$store.getters.AUTHENTICATED;
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch("CURRENT_SERVICE", 2);
      this.$store.dispatch("PROGRAMMED_ORDER_ORIGIN_CONTACT", null);
      this.initMain();
      this.initSaioMap();
      const map = new Map();
      map.initMap("mapSaio");
      this.$store.dispatch("PROGRAMMED_MAP_SET", map);
      this.getDefaultContact();
      this.getDefaultAddress();
    });
  },
  methods: {
    getDefaultContact() {
      this.$store.dispatch("GET_DEFAULT_CONTACT").then((response) => {
        if (response?.esPredeterminado) {
          const contact = {
            contactId: response.contactId,
            name: response.nombreContacto,
            phone: response.telefonoContacto,
            email: response.correoContacto,
            documentNumber: response.nroDocumentoContacto,
          };
          this.$store.dispatch("PROGRAMMED_ORDER_ORIGIN_CONTACT", contact);
          // this.$store.dispatch("HELPER_SET_ADD_DESTINATION_CONTACT", contact);
        }
      });
    },
    async getDefaultAddress() {
      this.showLoading()
      try {
        const addressList = await this.$store.dispatch('GET_FAVORITE_LOCATIONS')
        const predeterminedAddress = addressList.find(address => !address.puntoScharffId && address.isPredetermined)
        if (predeterminedAddress) {
          this.setAddress(predeterminedAddress)
        }
      } catch (e) {
        console.log({ e })
        const msg = e?.data?.message || 'Ocurrió un error.'
        this.showError(msg)
      } finally {
        this.$swal.close()
      }
    },
    async sleep(ms) {
      // eslint-disable-next-line no-promise-executor-return
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async setModel(origin, destination) {
      const fareRequest = {
        PackageValue: destination.productValue,
        PackageWidth: destination.packageSize.width,
        PackageHeight: destination.packageSize.height,
        PackageDeep: destination.packageSize.lengths,
        PackageWeight: destination.packageSize.weight,
        PackageType:
          destination.packageSize.type === "Pequeno"
            ? "Pequeño"
            : destination.packageSize.type,
        isMoreThan10: false,
        IsServiceExpress: false,
        IsServiceProgrammed: true,
        isServiceScharffPoint: false,
        OriginDistrictId: origin.districtId.id,
        DestinationDistrictId: destination.destinationAddress.districtId.id,
        ProgrammedServiceDate: null,
        IsUsingSubscription: false,
        Coupon: destination.coupon.fareCoupon,
      };
      return fareRequest;
    },
    async addCouponDestination(lstCoupon) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.lstDestination.length; index++) {
        this.$store.dispatch("PROGRAMMED_ORDER_SET_INDEX_DESTINATION", index);
        const element = this.lstDestination[index];
        const coupon = lstCoupon?.listCoupon[index];
        const destinationTemp = {
          index,
          item: {
            description: element.description,
            destinationAddress: {
              description: element.destinationAddress.description,
              lat: element.destinationAddress.lat,
              lng: element.destinationAddress.lng,
              districtId: element.destinationAddress.districtId,
              contact: element.destinationAddress.contact,
            },
            productValue: element.productValue,
            packageSize: element.packageSize,
            fare: 0,
            coupon: coupon.isValid === true ? coupon : null,
          },
        };
        // eslint-disable-next-line no-await-in-loop
        const obj = await this.setModel(this.origin, destinationTemp.item);
        this.$store
          .dispatch("PROGRAMMED_CALCULATE_FARE", obj)
          .then((response) => {
            destinationTemp.item.fare = response.totalFare;
            const destination = JSON.parse(JSON.stringify(destinationTemp));
            this.$store.dispatch(
              "PROGRAMMED_ORDER_SET_DESTINATION",
              destination
            );
          })
          .catch((error) => {
            this.$swal({
              title: "Error!",
              text: error,
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          });
        // eslint-disable-next-line no-await-in-loop
        await this.sleep(2000);
      }
    },
    getCardData(cardData) {
      this.$store.dispatch("PROGRAMMED_CARD", cardData);
    },
    getVoucherData(voucherData) {
      this.$store.dispatch("PROGRAMMED_VOUCHER", voucherData);
    },
    async getCoupon(coupon) {
      try {
        if (coupon != null) {
          if (coupon?.listCoupon[0].isValid) {
            this.$store.dispatch("PROGRAMMED_COUPON", coupon?.listCoupon[0]);
            const destinations = this.order.Destination;
            coupon?.listCoupon.forEach((element, index) => {
              if (element.isValid === true) {
                destinations[index].coupon = element.code;
                if (element.fareCoupon.Type === "VAL") {
                  destinations[index].discountFare = (
                    destinations[index].fare - element.fareCoupon.Value
                  ).toFixed(2);
                } else {
                  destinations[index].discountFare = (
                    destinations[index].fare -
                    destinations[index].fare * element.fareCoupon.Value
                  ).toFixed(2);
                }
                if (destinations[index].discountFare <= 0)
                  destinations[index].discountFare = 0;
              }
            });
            this.$store.dispatch("PROGRAMMED_APPEND_DESTINATION", destinations);
            this.showLoading();
            const sumall = this.order.Destination.map((item) =>
            item.coupon ? parseFloat(item.discountFare)
                : Number(item.fare) 
            ).reduce((prev, curr) => prev + curr, 0);
            this.$store.dispatch("PROGRAMMED_FARE", sumall);
            this.$swal.close();
          } else {
            this.showError("Ya no puedes usar este cupón");
          }
        } else {
          this.showError("Cupón invalido");
        }
      } catch (error) {
        console.error(error);
      }
    },
    setAddress(address) {
      this.$store.dispatch('PROGRAMMED_ORDER_ORIGIN_ADDRESS', {
        favoriteLocationId: address.favoriteLocationId,
        name: address.name,
        description: address.address,
        additionalAddress: address.addressAditional || '',
        addressDetail: address.addressAditional || '',
        lat: address.location?.Lat || 0,
        lng: address.location?.Lng || 0,
        departmentName: address.departmentName,
        provinceName: address.provinceName,
        districtName: address.districtName,
      })
      this.$store.dispatch('PROGRAMMED_ORDER_ORIGIN_DISTRICT', { id: address.distrito })
    },
    getContact(Contact) {
      if (this.modalContactType === "origin") {
        const oriContact = {
          contactId: Contact.contactId,
          name: Contact.nombreContacto,
          phone: Contact.telefonoContacto,
          email: Contact.correoContacto,
          documentNumber: Contact.nroDocumentoContacto,
        };
        this.$store.dispatch("PROGRAMMED_ORDER_ORIGIN_CONTACT", oriContact);
      } else if (this.modalContactType === "destination") {
        const destContact = {
          contactId: Contact.contactId,
          name: Contact.nombreContacto,
          phone: Contact.telefonoContacto,
          email: Contact.correoContacto,
          documentNumber: Contact.nroDocumentoContacto,
        };
        this.$store.dispatch("HELPER_SET_ADD_DESTINATION_CONTACT", destContact);
      } else {
        this.showError("Modal tipo de contacto no reconocido.");
      }
    },
  },
  computed: {
    progressNav() {
      return this.$store.getters.PROGRAMMED_PROGRESS_NAV;
    },
    contentDiv() {
      return this.$store.getters.STYLE_CONTENT_DIV;
    },
    modalContactType() {
      return this.$store.getters.MODAL_CONTACT_TYPE;
    },
    lstDestination() {
      return this.$store.getters.PROGRAMMED_ORDER_DESTINATIONN;
    },
    origin() {
      return this.$store.getters.PROGRAMMED_ORDER_ORIGIN;
    },
    stylesProgrammed() {
      return this.$store.getters.PROGRAMMED_STYLES;
    },
    order() {
      return this.$store.getters.PROGRAMMED_ORDER;
    },
  },
  watch: {
    stylesProgrammed: {
      handler() {
        this.$store.dispatch("STYLE_MAP_SAIO", this.stylesProgrammed.mapSaio);
        this.$store.dispatch(
          "STYLE_CONTENT_DIV",
          this.stylesProgrammed.contentDiv
        );
        this.$store.dispatch(
          "STYLE_CUSTOM_DIV",
          this.stylesProgrammed.generalDiv
        );
        // this.$store.dispatch(
        //   "STYLE_SHOW_PM_QUOTER",
        //   this.stylesProgrammed.pmQuoter
        // );
      },
      deep: true,
    },
    '$store.getters.PROGRAMMED_ORDER_STEP': function(val) {
      if (val === 1) {
        document.title = 'Programados | Fecha de recojo'
        this.$router.push({ name: 'ProgrammedStep1' })
      }
      if (val === 2) {
        document.title = 'Programados | Datos de Recojo'
        this.$router.push({ name: 'ProgrammedStep2' })
      }
      if (val === 3) {
        document.title = 'Programados | Gestiona tus paquetes'
        this.$router.push({ name: 'ProgrammedStep3' })
      }
      if (val === 4) {
        document.title = 'Programados | Datos de Confirmación'
        this.$router.push({ name: 'ProgrammedStep4' })
      }
      if (val === 5) {
        if (this.order.card && this.order.card.cardId === -1 && this.order.fare > 0) {
          document.title = 'Programados | Pago efectivo'
          this.$router.push({ name: 'ProgrammedStep4PagoEfectivo' })
        } else {
          document.title = 'Programados | Confirmación exitosa'
          this.$router.push({ name: 'ProgrammedStep5' })
        }
      }
    },
    '$store.getters.PROGRAMMED_ORDER_FARE': function(val) {
      if(this.$store.getters.PROGRAMMED_ORDER_STEP === 5) {
        if (val > 0) {
          document.title = 'Programados | Pago efectivo'
          this.$router.push({ name: 'ProgrammedStep4PagoEfectivo' })
        } else if (val == 0) {
          document.title = 'Programados | Confirmación exitosa'
          this.$router.push({ name: 'ProgrammedStep5' })
        }
      }
    },
  },
};
</script>

<style></style>
